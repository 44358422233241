import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Customizer, ThemeProvider } from '@fluentui/react';
import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import { initializeIcons } from '@fluentui/react/lib/Icons';

initializeIcons();

ReactDOM.render(
        <ThemeProvider>
            <BrowserRouter basename="/">
                    <App />
            </BrowserRouter>
        </ThemeProvider>,  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
