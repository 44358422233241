export const images: {
  [ key : string ]
  : string[]
} = {
  largeTrailers: [
        "123213.jpg",
        "123224.jpg",
        "123234.jpg",
        "123253.jpg",
        "123306.jpg",
        "123313.jpg",
        "123317.jpg",
        "123321.jpg",
        "123352.jpg",
        "123410.jpg",
  ],
  dinghyPark: [
    "123623.jpg",
    "123842.jpg",
    "123914.jpg",
    "123937.jpg",
] };
