import { mergeStyleSets } from '@fluentui/react';
import React from 'react';
import './App.css';
import { ImageGallery } from './Components/ImageGallery';
import { images } from './descs';
import { SafePureComponent } from './utils/SafePureComponent';

const css = mergeStyleSets({
    container: {
        selectors: {
            "td, table": {
                verticalAlign: "top",
            border: "solid 1px black",
            borderCollapse: "collapse",
            padding: "0px",
        }
    }
        }
});


class App extends SafePureComponent<unknown, unknown> {
    render() {
        return (
            <div className={css.container}>
                <h2>CSC Grounds</h2>
                <p>Thank you for taking the time to look over the unidentified property on the grounds</p>
                <p>The rules state that all property should be clearly labelled, and to be honest,
                    we don't know who a lot of these items belong to.</p>
                <p>The club has the right to dispose of unclaimed property after three months (some of these items have remained unidentified since 2021), 
                and there are remain 10 road trailers that don't appear to be marked.
                To avoid inadvertent disposal, please take a moment to look for your property in the galleries below, 
                    and if you have spotted anything of yours, please use the links to send the rear commodore a message
                    and make the effort to label your property at the club.
                </p>

                <p>Again, thank you for your cooperation. <br />On behalf of the grounds committee...<br />Tony Wieser<br />Commodore<br />Cam Sailing Club</p>
                <hr />
                <ImageGallery images={images.largeTrailers} label="On the main lawn" />

                <p>Additionally, there are some dinghies</p>
                <ImageGallery images={images.dinghyPark} label="In the dinghy park" />
                <h2>Remaining Jobs after Pollarding</h2>
                <table>
                    <thead>
                        <tr className="header">
                            <th>Remaining Jobs after pollarding</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{ "borderStyle": "solid" }}>
                        <tr className="odd">
                            <td>Trailer Park</td>
                            <td>
                                <p>Cut back willow overhanging dinghy park (tree surgeon?)</p>
                            </td>
                            <td>

                            </td>
                        </tr>
                        <tr className="even">
                            <td>Dinghy Park</td>
                            <td>
                                <p>
                                    Still a few elders to remove along the dinghy park hedgerow</p><p>
                                </p>
                            </td>
                            <td></td>
                        </tr>
                        <tr className="odd">
                            <td>Hedgerows</td>
                            <td>
                                <p>
                                    Tidy hedgerow behind caravans - to be completed</p><p>
                                    Cut back brambles behind shore berths in car park - to be completed</p><p>
                                </p>
                            </td>
                            <td></td>
                        </tr>
                        <tr className="even">
                            <td>Starters&quot; Box</td>
                            <td>
                                <p>Brush off green growth with wire brush</p>
                                <p>Paint with wood preservative</p>
                                <p>(NB dry weather only)</p>
                            </td>
                            <td>
                                <p>There are 10L preservative in pillbox, plus paint brushes.</p>
                                <p>Wire brush needed</p>
                            </td>
                        </tr>
                        <tr className="odd">
                            <td>Riverbank</td>
                            <td>Fill in holes using posts and willow branches/withies (corners next to both dinghy slipways)</td>
                            <td></td>
                        </tr>
                        <tr className="even">
                            <td>Pollarding</td>
                            <td>Willows on far bank and the field</td>
                            <td></td>
                        </tr>
                        <tr className="odd">
                            <td>Toilets</td>
                            <td>Hole in roof of outside loo</td>
                            <td>Small piece corrugated plastic</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        );
    }
}

export default App;
